<template>
  <div>
    <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="unitsOfMeasure"
      @uploadFromCSV="uploadFromCSV"
    />
    <Modal
      :modalShow="isShowModal"
      :messageGeneral="modalMessage"
      :title="settings.page.csvPopupTitle"
      v-on:toggle-modal="isShowModal = false"
      :isSuccessButton="false"
    />
  </div>
</template>
<script>
import { unitsOfMeasure } from '@/config/descriptions/unitsOfMeasure';
import { unitsOfMeasureTexts } from '@/config/pageTexts/unitsOfMeasure.json';
import Modal from "@/shared/UI/Modal.vue";
import axios from 'axios';

export default {
  name: 'UnitsOfMeasureTable',
  components: { Modal },
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'unitsOfMeasure/unitsOfMeasure',
          fields: 'unitsOfMeasure/fields',
          sortableFields: 'unitsOfMeasure/sortableFields',
          pages: 'unitsOfMeasure/pages',
          loading: 'unitsOfMeasure/loading',
          resultCount: 'unitsOfMeasure/resultCount',
          totalRecords: 'unitsOfMeasure/totalRecords',
          originalFields: 'unitsOfMeasure/originalFields',
          searchableAllFields: 'unitsOfMeasure/searchableAllFields',
          success: 'unitsOfMeasure/success',
          message: 'unitsOfMeasure/message',
        },
        actions: {
          fields: 'unitsOfMeasure/handleFields',
          delete: 'unitsOfMeasure/handleDeleteUnitsOfMeasure',
          reset: 'unitsOfMeasure/handleClearFilter',
          query: 'unitsOfMeasure/handleGetUnitsOfMeasureByQuery',
        },
      },
      settings: {
        describe: { ...unitsOfMeasure },
        page: { ...unitsOfMeasureTexts },
      },
      route: {
        create: 'CreateUnitOfMeasure',
        edit: 'EditUnitOfMeasure',
        preview: 'PreviewUnitOfMeasure',
      },
      isShowModal: false,
      modalMessage: '',
    };
  },
  methods: {
    async uploadFromCSV(file) {
      const token = localStorage.getItem('vue-token');
      const hostname = window.location.hostname;
      const isStagingDev = hostname.includes('127.0.0.1');
      const isStaging = hostname.split('.').includes('staging');

      const baseUrl = isStagingDev || isStaging
        ? `https://api.staging.ziqni.io`
        : `https://api.ziqni.com`;

      const path = `${baseUrl}/upload-units-of-measure-csv-file`;

      const formData = new FormData();
      formData.append('unitsOfMeasureFile', file, file.name)

      await axios.post(path, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      })
        .then(result => {
          if (result.data.results && result.data.results.length) {
            if (result.data.results[0].result === 'Updated') {
              this.modalMessage = this.settings.page.csvRecordsUpdated + ' ' + result.data.meta.totalRecords;
            } else {
              this.modalMessage = this.settings.page.csvRecordsCreated + ' ' + result.data.meta.totalRecords;
            }
            this.isShowModal = true;
          }
        })
        .catch(error => console.error(error))
    },
  }
};
</script>
